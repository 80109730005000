const _temp0 = require("../assets/img/project/3d-maze/3dmaze0.png");
const _temp1 = require("../assets/img/project/3d-maze/3dmaze1.png");
const _temp2 = require("../assets/img/project/3d-maze/3dmaze2.png");

const _temp3 = require("../assets/img/project/acid-drop/aciddrop0.png");
const _temp4 = require("../assets/img/project/acid-drop/aciddrop1.png");
const _temp5 = require("../assets/img/project/acid-drop/aciddrop2.png");

const _temp6 = require("../assets/img/project/asteroids/asteroids0.png");
const _temp7 = require("../assets/img/project/asteroids/asteroids1.png");

const _temp8 = require("../assets/img/project/attack-of-the-boom-boom/attackoftheboomboom0.png");
const _temp9 = require("../assets/img/project/attack-of-the-boom-boom/attackoftheboomboom1.png");

const _temp10 = require("../assets/img/project/ballistix/ballistix0.png");
const _temp11 = require("../assets/img/project/ballistix/ballistix1.png");
const _temp12 = require("../assets/img/project/ballistix/ballistix2.png");
const _temp13 = require("../assets/img/project/ballistix/ballistix3.png");

const _temp14 = require("../assets/img/project/brick/brick0.png");
const _temp15 = require("../assets/img/project/brick/brick1.png");
const _temp16 = require("../assets/img/project/brick/brick2.png");

const _temp17 = require("../assets/img/project/bg-teach/bg-teach.png");

const _temp18 = require("../assets/img/project/butterfly-blaster/butterflyblaster.png");

const _temp19 = require("../assets/img/project/car-shooter/carshooter0.png");
const _temp20 = require("../assets/img/project/car-shooter/carshooter1.png");

const _temp21 = require("../assets/img/project/carrier-owl/carrier-owl.png");

const _temp22 = require("../assets/img/project/catch-the-clown-nai/catchtheclownnai0.png");
const _temp23 = require("../assets/img/project/catch-the-clown-nai/catchtheclownnai1.png");

const _temp24 = require("../assets/img/project/catch-your-own/catchyourown0.png");
const _temp25 = require("../assets/img/project/catch-your-own/catchyourown1.png");
const _temp26 = require("../assets/img/project/catch-your-own/catchyourown2.png");
const _temp27 = require("../assets/img/project/catch-your-own/catchyourown3.png");

const _temp28 = require("../assets/img/project/django-conditions/djangoconditions.png");

const _temp29 = require("../assets/img/project/django-pigeon/django-pigeon.png");

const _temp30 = require("../assets/img/project/evil-clutches/evilclutches0.png");
const _temp31 = require("../assets/img/project/evil-clutches/evilclutches1.png");
const _temp32 = require("../assets/img/project/evil-clutches/evilclutches2.png");
const _temp33 = require("../assets/img/project/evil-clutches/evilclutches3.png");

const _temp34 = require("../assets/img/project/eyes/eyes.png");

const _temp35 = require("../assets/img/project/fish-catching/fishcatching0.png");
const _temp36 = require("../assets/img/project/fish-catching/fishcatching1.png");
const _temp37 = require("../assets/img/project/fish-catching/fishcatching2.png");
const _temp38 = require("../assets/img/project/fish-catching/fishcatching3.png");

const _temp39 = require("../assets/img/project/flag-frenzy/flagfrenzy0.png");
const _temp40 = require("../assets/img/project/flag-frenzy/flagfrenzy1.png");
const _temp41 = require("../assets/img/project/flag-frenzy/flagfrenzy2.png");

const _temp42 = require("../assets/img/project/flamingo/flamingo.png");

const _temp43 = require("../assets/img/project/galactic-mail/galacticmail0.png");
const _temp44 = require("../assets/img/project/galactic-mail/galacticmail1.png");

const _temp45 = require("../assets/img/project/goslinks/goslinks.png");

const _temp46 = require("../assets/img/project/helicopter-chase/helicopterchase0.png");
const _temp47 = require("../assets/img/project/helicopter-chase/helicopterchase1.png");

const _temp48 = require("../assets/img/project/jewel-hunter/jewel_hunter0.png");
const _temp49 = require("../assets/img/project/jewel-hunter/jewel_hunter1.png");
const _temp50 = require("../assets/img/project/jewel-hunter/jewel_hunter2.png");
const _temp51 = require("../assets/img/project/jewel-hunter/jewel_hunter3.png");

const _temp52 = require("../assets/img/project/k-and-l/k-and-l.png");

const _temp53 = require("../assets/img/project/koala-br8/koalabr80.png");
const _temp54 = require("../assets/img/project/koala-br8/koalabr81.png");
const _temp55 = require("../assets/img/project/koala-br8/koalabr82.png");
const _temp56 = require("../assets/img/project/koala-br8/koalabr83.png");
const _temp57 = require("../assets/img/project/koala-br8/koalabr84.png");

const _temp58 = require("../assets/img/project/lazarus/lazarus0.png");
const _temp59 = require("../assets/img/project/lazarus/lazarus1.png");
const _temp60 = require("../assets/img/project/lazarus/lazarus2.png");
const _temp61 = require("../assets/img/project/lazarus/lazarus3.png");

const _temp62 = require("../assets/img/project/letter-joy/letterjoy0.png");
const _temp63 = require("../assets/img/project/letter-joy/letterjoy1.png");

const _temp64 = require("../assets/img/project/letterboard/letterboard.png");

const _temp65 = require("../assets/img/project/mario-catcher/mariocatcher0.png");
const _temp66 = require("../assets/img/project/mario-catcher/mariocatcher1.png");

const _temp67 = require("../assets/img/project/maze-daze/mazedaze0.png");
const _temp68 = require("../assets/img/project/maze-daze/mazedaze1.png");
const _temp69 = require("../assets/img/project/maze-daze/mazedaze2.png");

const _temp70 = require("../assets/img/project/memory-match/memorymatch0.png");
const _temp71 = require("../assets/img/project/memory-match/memorymatch1.png");

const _temp72 = require("../assets/img/project/mockingbird/mockingbird.png");

const _temp73 = require("../assets/img/project/money-maker/moneymaker0.png");
const _temp74 = require("../assets/img/project/money-maker/moneymaker1.png");

const _temp75 = require("../assets/img/project/pacmans-back-for-those-dots/pacmansbackforthosedots0.png");
const _temp76 = require("../assets/img/project/pacmans-back-for-those-dots/pacmansbackforthosedots1.png");
const _temp77 = require("../assets/img/project/pacmans-back-for-those-dots/pacmansbackforthosedots2.png");

const _temp78 = require("../assets/img/project/pacmans-back-in-red/pacmansbackinred0.png");
const _temp79 = require("../assets/img/project/pacmans-back-in-red/pacmansbackinred1.png");
const _temp80 = require("../assets/img/project/pacmans-back-in-red/pacmansbackinred2.png");
const _temp81 = require("../assets/img/project/pacmans-back-in-red/pacmansbackinred3.png");
const _temp82 = require("../assets/img/project/pacmans-back-in-red/pacmansbackinred4.png");
const _temp83 = require("../assets/img/project/pacmans-back-in-red/pacmansbackinred5.png");

const _temp84 = require("../assets/img/project/perdiem/perdiem.png");

const _temp85 = require("../assets/img/project/platform-time/platformtime0.png");
const _temp86 = require("../assets/img/project/platform-time/platformtime1.png");
const _temp87 = require("../assets/img/project/platform-time/platformtime2.png");
const _temp88 = require("../assets/img/project/platform-time/platformtime3.png");

const _temp89 = require("../assets/img/project/pong/pong.png");

const _temp90 = require("../assets/img/project/pyramid-panic/pyramidpanic0.png");
const _temp91 = require("../assets/img/project/pyramid-panic/pyramidpanic1.png");
const _temp92 = require("../assets/img/project/pyramid-panic/pyramidpanic2.png");

const _temp93 = require("../assets/img/project/rooster/rooster.png");

const _temp94 = require("../assets/img/project/runaway-dog/runawaydog0.png");
const _temp95 = require("../assets/img/project/runaway-dog/runawaydog1.png");

const _temp96 = require("../assets/img/project/seared-quail/searedquail0.png");
const _temp97 = require("../assets/img/project/seared-quail/searedquail1.png");
const _temp98 = require("../assets/img/project/seared-quail/searedquail2.png");
const _temp99 = require("../assets/img/project/seared-quail/searedquail3.png");

const _temp100 = require("../assets/img/project/shooter/shooter0.png");
const _temp101 = require("../assets/img/project/shooter/shooter1.png");

const _temp102 = require("../assets/img/project/shrek-adventure-game-nai/shrekadventuregamenai0.png");
const _temp103 = require("../assets/img/project/shrek-adventure-game-nai/shrekadventuregamenai1.png");
const _temp104 = require("../assets/img/project/shrek-adventure-game-nai/shrekadventuregamenai2.png");

const _temp105 = require("../assets/img/project/smiley-the-smiley/smileythesmiley0.png");
const _temp106 = require("../assets/img/project/smiley-the-smiley/smileythesmiley1.png");
const _temp107 = require("../assets/img/project/smiley-the-smiley/smileythesmiley2.png");

const _temp108 = require("../assets/img/project/spring-thing/springthing0.png");
const _temp109 = require("../assets/img/project/spring-thing/springthing1.png");
const _temp110 = require("../assets/img/project/spring-thing/springthing2.png");

const _temp111 = require("../assets/img/project/super-rainbow-reef/superrainbowreef0.png");
const _temp112 = require("../assets/img/project/super-rainbow-reef/superrainbowreef1.png");
const _temp113 = require("../assets/img/project/super-rainbow-reef/superrainbowreef2.png");
const _temp114 = require("../assets/img/project/super-rainbow-reef/superrainbowreef3.png");
const _temp115 = require("../assets/img/project/super-rainbow-reef/superrainbowreef4.png");

const _temp116 = require("../assets/img/project/switchy-switchy-nai/switchyswitchynai0.png");
const _temp117 = require("../assets/img/project/switchy-switchy-nai/switchyswitchynai1.png");
const _temp118 = require("../assets/img/project/switchy-switchy-nai/switchyswitchynai2.png");

const _temp119 = require("../assets/img/project/tank-war/tankwar0.png");
const _temp120 = require("../assets/img/project/tank-war/tankwar1.png");
const _temp121 = require("../assets/img/project/tank-war/tankwar2.png");

const _temp122 = require("../assets/img/project/tic-tac-toe/tictactoe0.png");
const _temp123 = require("../assets/img/project/tic-tac-toe/tictactoe1.png");

const _temp124 = require("../assets/img/project/tree-obviator/treeobviator0.png");
const _temp125 = require("../assets/img/project/tree-obviator/treeobviator1.png");
const _temp126 = require("../assets/img/project/tree-obviator/treeobviator2.png");

const _temp127 = require("../assets/img/project/tug-o-war/tugowar.png");

const _temp128 = require("../assets/img/project/walls-of-pure-evil/wallsofpureevil0.png");
const _temp129 = require("../assets/img/project/walls-of-pure-evil/wallsofpureevil1.png");

const _temp130 = require("../assets/img/project/whack-a-mole/whackamole0.png");
const _temp131 = require("../assets/img/project/whack-a-mole/whackamole1.png");

const _temp132 = require("../assets/img/project/wingman-sam/wingmansam0.png");
const _temp133 = require("../assets/img/project/wingman-sam/wingmansam1.png");
const _temp134 = require("../assets/img/project/wingman-sam/wingmansam2.png");
const _temp135 = require("../assets/img/project/wingman-sam/wingmansam3.png");

module.exports = {
  "3d-maze":   {
    "3dmaze0": _temp0,
    "3dmaze1": _temp1,
    "3dmaze2": _temp2
  },
  "acid-drop":   {
    "aciddrop0": _temp3,
    "aciddrop1": _temp4,
    "aciddrop2": _temp5
  },
  "asteroids":   {
    "asteroids0": _temp6,
    "asteroids1": _temp7
  },
  "attack-of-the-boom-boom":   {
    "attackoftheboomboom0": _temp8,
    "attackoftheboomboom1": _temp9
  },
  "ballistix":   {
    "ballistix0": _temp10,
    "ballistix1": _temp11,
    "ballistix2": _temp12,
    "ballistix3": _temp13
  },
  "brick":   {
    "brick0": _temp14,
    "brick1": _temp15,
    "brick2": _temp16
  },
  "bg-teach":   {
    "bg-teach": _temp17
  },
  "butterfly-blaster":   {
    "butterflyblaster": _temp18
  },
  "car-shooter":   {
    "carshooter0": _temp19,
    "carshooter1": _temp20
  },
  "carrier-owl":   {
    "carrier-owl": _temp21
  },
  "catch-the-clown-nai":   {
    "catchtheclownnai0": _temp22,
    "catchtheclownnai1": _temp23
  },
  "catch-your-own":   {
    "catchyourown0": _temp24,
    "catchyourown1": _temp25,
    "catchyourown2": _temp26,
    "catchyourown3": _temp27
  },
  "django-conditions":   {
    "djangoconditions": _temp28
  },
  "django-pigeon":   {
    "django-pigeon": _temp29
  },
  "evil-clutches":   {
    "evilclutches0": _temp30,
    "evilclutches1": _temp31,
    "evilclutches2": _temp32,
    "evilclutches3": _temp33
  },
  "eyes":   {
    "eyes": _temp34
  },
  "fish-catching":   {
    "fishcatching0": _temp35,
    "fishcatching1": _temp36,
    "fishcatching2": _temp37,
    "fishcatching3": _temp38
  },
  "flag-frenzy":   {
    "flagfrenzy0": _temp39,
    "flagfrenzy1": _temp40,
    "flagfrenzy2": _temp41
  },
  "flamingo":   {
    "flamingo": _temp42
  },
  "galactic-mail":   {
    "galacticmail0": _temp43,
    "galacticmail1": _temp44
  },
  "goslinks":   {
    "goslinks": _temp45
  },
  "helicopter-chase":   {
    "helicopterchase0": _temp46,
    "helicopterchase1": _temp47
  },
  "jewel-hunter":   {
    "jewel_hunter0": _temp48,
    "jewel_hunter1": _temp49,
    "jewel_hunter2": _temp50,
    "jewel_hunter3": _temp51
  },
  "k-and-l":   {
    "k-and-l": _temp52
  },
  "koala-br8":   {
    "koalabr80": _temp53,
    "koalabr81": _temp54,
    "koalabr82": _temp55,
    "koalabr83": _temp56,
    "koalabr84": _temp57
  },
  "lazarus":   {
    "lazarus0": _temp58,
    "lazarus1": _temp59,
    "lazarus2": _temp60,
    "lazarus3": _temp61
  },
  "letter-joy":   {
    "letterjoy0": _temp62,
    "letterjoy1": _temp63
  },
  "letterboard":   {
    "letterboard": _temp64
  },
  "mario-catcher":   {
    "mariocatcher0": _temp65,
    "mariocatcher1": _temp66
  },
  "maze-daze":   {
    "mazedaze0": _temp67,
    "mazedaze1": _temp68,
    "mazedaze2": _temp69
  },
  "memory-match":   {
    "memorymatch0": _temp70,
    "memorymatch1": _temp71
  },
  "mockingbird":   {
    "mockingbird": _temp72
  },
  "money-maker":   {
    "moneymaker0": _temp73,
    "moneymaker1": _temp74
  },
  "pacmans-back-for-those-dots":   {
    "pacmansbackforthosedots0": _temp75,
    "pacmansbackforthosedots1": _temp76,
    "pacmansbackforthosedots2": _temp77
  },
  "pacmans-back-in-red":   {
    "pacmansbackinred0": _temp78,
    "pacmansbackinred1": _temp79,
    "pacmansbackinred2": _temp80,
    "pacmansbackinred3": _temp81,
    "pacmansbackinred4": _temp82,
    "pacmansbackinred5": _temp83
  },
  "perdiem":   {
    "perdiem": _temp84
  },
  "platform-time":   {
    "platformtime0": _temp85,
    "platformtime1": _temp86,
    "platformtime2": _temp87,
    "platformtime3": _temp88
  },
  "pong":   {
    "pong": _temp89
  },
  "pyramid-panic":   {
    "pyramidpanic0": _temp90,
    "pyramidpanic1": _temp91,
    "pyramidpanic2": _temp92
  },
  "rooster":   {
    "rooster": _temp93
  },
  "runaway-dog":   {
    "runawaydog0": _temp94,
    "runawaydog1": _temp95
  },
  "seared-quail":   {
    "searedquail0": _temp96,
    "searedquail1": _temp97,
    "searedquail2": _temp98,
    "searedquail3": _temp99
  },
  "shooter":   {
    "shooter0": _temp100,
    "shooter1": _temp101
  },
  "shrek-adventure-game-nai":   {
    "shrekadventuregamenai0": _temp102,
    "shrekadventuregamenai1": _temp103,
    "shrekadventuregamenai2": _temp104
  },
  "smiley-the-smiley":   {
    "smileythesmiley0": _temp105,
    "smileythesmiley1": _temp106,
    "smileythesmiley2": _temp107
  },
  "spring-thing":   {
    "springthing0": _temp108,
    "springthing1": _temp109,
    "springthing2": _temp110
  },
  "super-rainbow-reef":   {
    "superrainbowreef0": _temp111,
    "superrainbowreef1": _temp112,
    "superrainbowreef2": _temp113,
    "superrainbowreef3": _temp114,
    "superrainbowreef4": _temp115
  },
  "switchy-switchy-nai":   {
    "switchyswitchynai0": _temp116,
    "switchyswitchynai1": _temp117,
    "switchyswitchynai2": _temp118
  },
  "tank-war":   {
    "tankwar0": _temp119,
    "tankwar1": _temp120,
    "tankwar2": _temp121
  },
  "tic-tac-toe":   {
    "tictactoe0": _temp122,
    "tictactoe1": _temp123
  },
  "tree-obviator":   {
    "treeobviator0": _temp124,
    "treeobviator1": _temp125,
    "treeobviator2": _temp126
  },
  "tug-o-war":   {
    "tugowar": _temp127
  },
  "walls-of-pure-evil":   {
    "wallsofpureevil0": _temp128,
    "wallsofpureevil1": _temp129
  },
  "whack-a-mole":   {
    "whackamole0": _temp130,
    "whackamole1": _temp131
  },
  "wingman-sam":   {
    "wingmansam0": _temp132,
    "wingmansam1": _temp133,
    "wingmansam2": _temp134,
    "wingmansam3": _temp135
  }
}